<template>
  <div class="settings-vat d-flex flex-column height100" :key="key">
    <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-5">
      <div class="text-h6 font-weight-bold">VAT</div>
      <div class="disabled--text">Here you can manage VAT options for the platform</div>
    </div>
    <v-divider></v-divider>
    <v-card tile flat color="transparent" v-if="!data.length" class="d-flex justify-center align-center" height="300">
      <div>
        <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
        <div class="text-center text--text mt-4">Sorry, but nothing found</div>
      </div>
    </v-card>
    <v-data-table v-else :items-per-page="-1" hide-default-footer fixed-header :headers="headers" :items="data" item-key="id">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id" height="50" class="link" @click="openItem(item)">
            <td>{{ item.title }}</td>
            <td>{{ item.type == 'percent' ? 'Percent' : 'Amount' }}</td>
            <td><span v-if="item.type == 'fixed'">SAR</span> {{ item.value }}<span v-if="item.type == 'percent'">%</span></td>
            <td class="text-center">
              <v-checkbox
                @click.stop="active(item)"
                class="mt-0 d-inline-block"
                v-model="item.active"
                color="success"
                hide-details
              ></v-checkbox>
            </td>
            <td>
              <span class="primary--text" v-if="item.default">
                <v-icon color="primary">mdi-check</v-icon>
                Default
              </span>
              <v-btn v-else @click.stop="defaultSet(item)" outlined class="primary--text set">Set default</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div class="pl-lg-9 px-sm-7 py-3 px-3 mb-5">
      <v-btn @click="modal = true" depressed height="30" width="106" class="rounded primary">Add new</v-btn>
    </div>
    <v-dialog v-model="modal" max-width="460">
      <v-card flat outlined class="px-8 py-6">
        <div class="font-weight-bold mb-4 text-h6">{{ modalData.id ? 'Edit VAT' : 'Add VAT' }}</div>
        <div class="mb-2">Title</div>
        <v-text-field
          class="field46"
          :error-messages="titleErrors"
          v-model="modalData.title"
          placeholder="Title"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="mb-2 mt-n2">Type</div>
        <v-select
          class="field46"
          :error-messages="typeErrors"
          v-model="modalData.type"
          :items="items"
          placeholder="Select type"
          outlined
          dense
          required
          color="primary"
          height="46"
        >
          <template v-slot:selection="{ item }">
            <span class="text-capitalize">{{ item }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span class="text-capitalize">{{ item }}</span>
          </template>
        </v-select>
        <div v-if="modalData.type">
          <div class="mb-2 mt-n2">{{ modalData.type == 'percent' ? 'Percent, %' : 'Amount, SAR' }}</div>
          <v-text-field
            class="field46"
            style="max-width: 196px"
            :error-messages="valueErrors"
            v-model="modalData.value"
            placeholder="Enter value"
            outlined
            dense
            required
            type="number"
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="text-end mt-3">
          <v-btn @click="modal = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text">Cancel</span>
          </v-btn>
          <v-btn @click="modalData.id ? update() : save()" depressed class="primary rounded text-body-2" width="106" height="30">
            {{ modalData.id ? 'Save' : 'Create' }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      modalData: {},
      key: 1,
      headers: [
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Value', value: 'value', sortable: false },
        { text: 'Active', value: 'active', sortable: false, align: 'center' },
        { text: 'Default', value: 'default', sortable: false },
      ],
      items: ['fixed', 'percent'],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getSettingsVat');
  },
  methods: {
    notifi(btn) {
      if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async save() {
      this.error = [];
      const data = new Object();
      data.title = this.modalData.title || '';
      data.type = this.modalData.type || '';
      data.value = +this.modalData.value * 100 || 0;
      await this.$store
        .dispatch('addSettingsVat', data)
        .then((res) => {
          res.data.value = res.data.value / 100 || 0;
          this.data.push(res.data);
          this.modal = false;
          ++this.key;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.title = this.modalData.title || '';
      data.type = this.modalData.type || '';
      data.value = +this.modalData.value * 100 || 0;
      await this.$store
        .dispatch('updateSettingsVat', { id: this.modalData.id, data: data })
        .then(() => {
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async active(i) {
      this.error = [];
      await this.$store.dispatch('activeSettingsVat', { id: i.id, data: { active: i.active } }).catch((e) => {
        this.error = e.response.data.error;
        this.modal ? '' : this.notifi('500');
      });
    },
    async defaultSet(i) {
      this.error = [];
      await this.$store.dispatch('defaultSettingsVat', i.id).catch((e) => {
        this.error = e.response.data.error;
        this.modal ? '' : this.notifi('500');
      });
    },
    openItem(i) {
      this.modal = true;
      this.$nextTick(() => {
        this.modalData = i;
      });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.settingsVat;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Selected type is not valid');
      return errors;
    },
    valueErrors() {
      const errors = [];
      this.error.find((item) => item == 'value__required') && errors.push('Please enter value');
      this.error.find((item) => item == 'value__invalid') && errors.push('Provided value is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.settings-vat {
  table thead tr th {
    background: white !important;
  }
  .v-btn.primary--text.set {
    background: var(--v-primary-lighten3);
  }
}
</style>
