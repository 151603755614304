<template>
  <div class="settings-app" :key="key">
    <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-4">
      <div class="text-h6 font-weight-bold">Mobile application settings</div>
      <div class="text--text">Customer mobile application settings</div>
    </div>
    <div class="pl-lg-9 px-sm-7 mb-3 px-3 font-weight-bold">Mobile application main screen settings</div>
    <v-divider></v-divider>
    <v-data-table :items-per-page="-1" hide-default-footer fixed-header :headers="headers" :items="data.explore_sections" item-key="id">
      <template v-slot:body="{ items }">
        <draggable :list="items" tag="tbody" @change="changeOrder($event)" handle=".handle">
          <tr v-for="item in items" :key="item.id" height="50" @click="openItem(item)">
            <td class="pr-0" style="width: 40px !important">
              <img class="move-icon handle pt-2" src="@/assets/icon/move.svg" alt="icon" />
            </td>
            <td class="text-center pl-0" style="width: 30px !important">
              <v-checkbox
                @click.stop="checkbox(item)"
                class="mt-0 d-inline-block"
                v-model="item.visible"
                color="success"
                hide-details
              ></v-checkbox>
            </td>
            <td>{{ item.type ? typeItems.find((i) => i.name == item.type).title : '' }}</td>
            <td>{{ item.title_en }}</td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <v-divider v-if="data.explore_sections.length"></v-divider>
    <div class="pl-lg-9 px-sm-7 py-3 px-3">
      <v-btn @click="modal = true" depressed height="30" width="106" class="rounded primary">
        <span class="text-body-2">Add new</span>
      </v-btn>
    </div>
    <v-divider v-if="!data.explore_sections.find((i) => i.id == undefined)"></v-divider>
    <div class="pl-lg-9 px-sm-7 my-3 px-3 font-weight-bold">Mobile application version</div>
    <v-divider></v-divider>
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-start">Platform</th>
            <th class="text-start">Version name</th>
            <th class="text-start">Version code</th>
            <th class="text-center">Update required</th>
          </tr>
        </thead>
        <tbody>
          <tr height="70">
            <td>
              <v-text-field class="field46" value="iOS" hide-details outlined dense height="46" disabled></v-text-field>
            </td>
            <td>
              <v-text-field
                class="field46"
                :class="error.find((item) => item == 'ios_version_name__required') ? 'v-input--has-state error--text' : ''"
                v-model="data.ios.version_name"
                placeholder="Enter version name"
                @change="updateApp(data)"
                hide-details
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                class="field46"
                :class="error.find((item) => item == 'ios_version_code__required') ? 'v-input--has-state error--text' : ''"
                v-model="data.ios.version_code"
                placeholder="Enter version code"
                @change="updateApp(data)"
                hide-details
                outlined
                dense
                required
                type="number"
                color="primary"
                height="46"
                :disabled="!data.ios.version_name"
              ></v-text-field>
            </td>
            <td class="text-center">
              <v-checkbox
                @change="updateApp(data)"
                class="mt-0 d-inline-block"
                v-model="data.ios.update_required"
                color="success"
                hide-details
              ></v-checkbox>
            </td>
          </tr>
          <tr height="70">
            <td>
              <v-text-field class="field46" value="Android" hide-details outlined dense height="46" disabled></v-text-field>
            </td>
            <td>
              <v-text-field
                class="field46"
                :class="error.find((item) => item == 'android_version_name__required') ? 'v-input--has-state error--text' : ''"
                v-model="data.android.version_name"
                placeholder="Enter version name"
                @change="updateApp(data)"
                hide-details
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                class="field46"
                :class="error.find((item) => item == 'android_version_code__required') ? 'v-input--has-state error--text' : ''"
                v-model="data.android.version_code"
                placeholder="Enter version code"
                @change="updateApp(data)"
                hide-details
                outlined
                dense
                required
                type="number"
                color="primary"
                height="46"
                :disabled="!data.android.version_name"
              ></v-text-field>
            </td>
            <td class="text-center">
              <v-checkbox
                @change="updateApp(data)"
                class="mt-0 d-inline-block"
                v-model="data.android.update_required"
                color="success"
                hide-details
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="modal" max-width="700">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">Main screen settings</div>
        <v-card flat outlined class="px-10 py-5">
          <div class="mb-3">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="12" :class="modalData.type == 'trips_group' ? 'pb-0' : ''">
              <v-select
                class="field46"
                :error-messages="typeErrors"
                v-model="modalData.type"
                :items="typeItems"
                placeholder="Enter type"
                item-text="title"
                item-value="name"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pt-0" v-if="modalData.type == 'trips_group'">
              <v-select
                class="field46"
                :error-messages="tripsGroupErrors"
                v-model="modalData.trips_group"
                :items="tripsGroups"
                placeholder="Enter trips group"
                item-text="title"
                item-value="id"
                return-object
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col cols="6">
              <v-textarea
                class="field46"
                v-model="modalData.title_en"
                :error-messages="titleEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="36"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                class="field46"
                v-model="modalData.title_ar"
                :error-messages="titleArErrors"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="36"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="modalData.id ? update() : save()" depressed class="primary rounded text-body-2" width="106" height="30">
            Done
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      modal: false,
      modalData: {},
      key: 1,
      headers: [
        { text: '', value: 'icon', sortable: false, align: 'center' },
        { text: 'Visible', value: 'visible', sortable: false, align: 'center', class: 'pl-0' },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Title', value: 'title_en', sortable: false },
      ],
      typeItems: [
        { name: 'live_new_experience', title: 'Live new experience' },
        { name: 'trips', title: 'Trips' },
        { name: 'trendy_destinations', title: 'Trendy destinations' },
        { name: 'trip_request', title: 'Trip request' },
        { name: 'experts', title: 'Experts' },
        { name: 'travel_experiences', title: 'Travel experiences' },
        { name: 'reviews', title: 'Reviews' },
        { name: 'trips_group', title: 'Trips group' },
      ],
      error: [],
    };
  },
  mounted() {
    this.data.length ? '' : this.$store.dispatch('getSettingsMobile');
    this.tripsGroups.length ? '' : this.$store.dispatch('getTripsGroupsShort');
  },
  methods: {
    notifi(btn) {
      if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    changeOrder(e) {
      this.updateOrder(e.moved.element.id, e.moved.newIndex);
    },
    openItem(i) {
      this.modal = true;
      this.$nextTick(() => {
        this.modalData = i;
      });
    },
    checkbox(i) {
      this.modalData = i;
      this.update();
    },
    async save() {
      this.error = [];
      const data = new Object();
      data.type = this.modalData.type || '';
      data.title_en = this.modalData.title_en || '';
      data.title_ar = this.modalData.title_ar || '';
      data.visible = this.modalData.visible || false;
      this.modalData.type == 'trips_group' ? (data.trips_group = this.modalData.trips_group.id) : '';
      await this.$store
        .dispatch('createSettingsMobileExplore', data)
        .then(() => {
          this.modal = false;
          ++this.key;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.type = this.modalData.type || '';
      data.title_en = this.modalData.title_en || '';
      data.title_ar = this.modalData.title_ar || '';
      data.visible = this.modalData.visible || false;
      this.modalData.type == 'trips_group' ? (data.trips_group = this.modalData.trips_group.id) : '';
      await this.$store
        .dispatch('updateSettingsMobileExplore', { id: this.modalData.id, data: data })
        .then(() => {
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async updateOrder(id, order) {
      this.error = [];
      const data = new Object();
      data.order = +order || 0;
      await this.$store.dispatch('updateSettingsMobileExploreOrder', { id: id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    async updateApp(item) {
      this.error = [];
      const data = new Object();
      data.android_version_name = item.android.version_name || '';
      data.ios_version_name = item.ios.version_name || '';
      data.android_version_code = +item.android.version_code || '';
      data.ios_version_code = +item.ios.version_code || '';
      data.android_update_required = item.android.update_required || false;
      data.ios_update_required = item.ios.update_required || false;
      await this.$store.dispatch('updateSettingsMobile', data).catch((e) => {
        this.error = e.response.data.error;
      });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.settingsMobile;
    },
    tripsGroups() {
      return this.$store.getters.tripsGroupsShort;
    },
    tripsGroupErrors() {
      const errors = [];
      this.error.find((item) => item == 'trips_group__required') && errors.push('Please select trips group');
      this.error.find((item) => item == 'trips_group__invalid') && errors.push('Selected trips group is not valid');
      return errors;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please enter type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Provided type is not valid');
      this.error.find((item) => item == 'type__exists') &&
        errors.push('Sorry, but you can not add more than one section with the same type');
      return errors;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.settings-app {
  table thead tr th {
    background: white !important;
  }
}
</style>
