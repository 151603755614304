<template>
  <div class="settings">
    <div class="text-h5 font-weight-bold">Settings</div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'trip-types'">
          <Types />
        </div>
        <div class="width100" v-if="link == 'trip-requests'">
          <Requests />
        </div>
        <div class="width100" v-if="link == 'bookings'">
          <Bookings />
        </div>
        <div class="width100" v-if="link == 'trips'">
          <Trips />
        </div>
        <div class="width100" v-if="link == 'tabby'">
          <Tabby />
        </div>
        <div class="width100" v-if="link == 'surprise-trips'">
          <SurpriseTrips />
        </div>
        <div class="width100" v-if="link == 'mobile-application'">
          <Application />
        </div>
        <div class="width100" v-if="link == 'web-site'">
          <Web />
        </div>
        <div class="width100" v-if="link == 'support-service'">
          <Service />
        </div>
        <div class="width100" v-if="link == 'billing'">
          <Billing />
        </div>
        <div class="width100" v-if="link == 'vat'">
          <Vat />
        </div>
        <div class="width100" v-if="link == 'service-fee'">
          <ServiceFee />
        </div>
        <!-- <div class="width100" v-if="link == 'digital-wallet'">
          <Wallet />
        </div> -->
      </div>
    </v-card>
  </div>
</template>

<script>
import Types from '@/components/settings/Types';
import Requests from '@/components/settings/Requests';
import Bookings from '@/components/settings/Bookings';
import Tabby from '@/components/settings/Tabby';
import Trips from '@/components/settings/Trips';
import SurpriseTrips from '@/components/settings/SurpriseTrips';
import Application from '@/components/settings/Application';
import Web from '@/components/settings/Web';
import Service from '@/components/settings/Service';
import Billing from '@/components/settings/Billing';
import Vat from '@/components/settings/Vat';
import ServiceFee from '@/components/settings/ServiceFee';
// import Wallet from '@/components/settings/Wallet';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Types,
    Requests,
    Bookings,
    Tabby,
    Trips,
    SurpriseTrips,
    Application,
    Web,
    Service,
    Billing,
    Vat,
    ServiceFee,
    // Wallet,
  },
  data() {
    return {
      link: this.$route.params.tab || 'trip-types',
      menuItems: [
        { link: 'trip-types', title: 'Trip types' },
        { link: 'trip-requests', title: 'Trip requests' },
        { link: 'bookings', title: 'Bookings' },
        { link: 'tabby', title: 'Tabby' },
        { link: 'trips', title: 'Trips' },
        { link: 'surprise-trips', title: 'Surprise trips' },
        { link: 'mobile-application', title: 'Mobile application' },
        { link: 'web-site', title: 'Web Site' },
        { link: 'support-service', title: 'Support service' },
        { link: 'billing', title: 'Billing' },
        { link: 'vat', title: 'VAT' },
        { link: 'service-fee', title: 'Service fee' },
        // { link: 'digital-wallet', title: 'Digital wallet' },
      ],
    };
  },
  // mounted() {
  //   this.$store.dispatch('getTripsItem');
  // },
  methods: {
    clickMenu(l) {
      this.link = l;
      this.$router.push(`/settings/${l}`);
    },
  },
  // computed: {
  //   data() {
  //     return this.$store.getters.trips;
  //   },
  // },
  destroyed() {
    this.$store.dispatch('clearSettings');
    this.$store.dispatch('setTripsList');
    this.$store.dispatch('setPaymentProviders');
  },
};
</script>
