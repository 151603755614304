<template>
  <div class="settings-billing d-flex flex-column height100">
    <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-5">
      <div class="text-h6 font-weight-bold">Billing</div>
      <div class="text--text">Billing settings</div>
    </div>
    <div class="pl-lg-9 px-sm-7 px-3 d-flex flex-sm-nowrap flex-wrap item-content">
      <div class="width100 me-sm-2">
        <div class="mb-2 font-weight-bold">Legal name</div>
        <v-text-field
          class="field46"
          style="max-width: 375px"
          v-model="data.legal_name"
          placeholder="Enter legal name"
          :error-messages="legalErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
      </div>
      <!-- <div class="width100 mt-sm-0 mt-n2 ms-sm-2">
        <div class="mb-2 font-weight-bold">VAT, %</div>
        <v-text-field
          class="field46"
          style="max-width: 186px"
          v-model="data.vat"
          placeholder="Enter VAT, %"
          :error-messages="vatErrors"
          outlined
          dense
          required
          type="number"
          color="primary"
          height="46"
        ></v-text-field>
      </div> -->
    </div>
    <div class="pl-lg-9 px-sm-7 px-3 mt-n2 d-flex flex-sm-nowrap flex-wrap item-content">
      <div class="width100 me-sm-2">
        <div class="mb-2 font-weight-bold">VAT number</div>
        <v-text-field
          class="field46"
          style="max-width: 375px"
          v-model="data.vat_number"
          placeholder="VN123456"
          :error-messages="vatNumberErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
      </div>
      <!-- <div class="width100 mt-sm-0 mt-n2 ms-sm-2">
        <div class="mb-2 font-weight-bold">Service fee, %</div>
        <v-text-field
          class="field46"
          style="max-width: 186px"
          v-model="data.service_fee"
          placeholder="Enter service fee, %"
          :error-messages="serviceFeeErrors"
          outlined
          dense
          required
          type="number"
          color="primary"
          height="46"
        ></v-text-field>
      </div> -->
    </div>
    <div class="pl-lg-9 px-sm-7 px-3 mt-n2 item-content">
      <div class="width100 me-sm-2">
        <div class="mb-2 font-weight-bold">Web site payment gateway</div>
        <v-select
          class="field46"
          style="max-width: 375px"
          v-model="data.web_payment_provider"
          placeholder="Select payment system"
          :error-messages="webErrors"
          :items="items"
          item-text="title"
          item-value="value"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
      </div>
    </div>
    <div class="pl-lg-9 px-sm-7 px-3 mb-5 mt-n2 item-content">
      <div class="width100 me-sm-2">
        <div class="mb-2 font-weight-bold">Mobile app payment gateway</div>
        <v-select
          class="field46"
          style="max-width: 375px"
          v-model="data.mobile_payment_provider"
          placeholder="Select payment system"
          :error-messages="mobileErrors"
          :items="items"
          item-text="title"
          item-value="value"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
      </div>
    </div>
    <v-spacer></v-spacer>
    <div>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="save" depressed color="primary" class="rounded" width="106" height="30"><span class="text-body-2">Save</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getSettingsBilling');
    this.$store.dispatch('getPaymentProviders');
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Setting billing successfully updated',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async save() {
      this.error = [];
      const data = new Object();
      data.legal_name = this.data.legal_name || '';
      data.vat_number = this.data.vat_number || '';
      data.web_payment_provider = this.data.web_payment_provider ? this.data.web_payment_provider.value : '';
      data.mobile_payment_provider = this.data.mobile_payment_provider ? this.data.mobile_payment_provider.value : '';
      await this.$store
        .dispatch('updateSettingsBilling', data)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.settingsBilling;
    },
    items() {
      return this.$store.getters.paymentProviders;
    },
    legalErrors() {
      const errors = [];
      this.error.find((item) => item == 'legal_name__required') && errors.push('Please enter legal name');
      this.error.find((item) => item == 'legal_name__invalid') && errors.push('Provided legal name is not valid');
      return errors;
    },
    vatNumberErrors() {
      const errors = [];
      this.error.find((item) => item == 'vat_number__required') && errors.push('Please enter vat number');
      this.error.find((item) => item == 'vat_number__invalid') && errors.push('Provided vat number is not valid');
      return errors;
    },
    webErrors() {
      const errors = [];
      this.error.find((item) => item == 'web_payment_provider__required') && errors.push('Please select web payment provider');
      this.error.find((item) => item == 'web_payment_provider__invalid') && errors.push('Selected web payment provider is not valid');
      return errors;
    },
    mobileErrors() {
      const errors = [];
      this.error.find((item) => item == 'mobile_payment_provider__required') && errors.push('Please select mobile payment provider');
      this.error.find((item) => item == 'mobile_payment_provider__invalid') && errors.push('Selected mobile payment provider is not valid');
      return errors;
    },
  },
};
</script>
