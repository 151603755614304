<template>
  <div class="settings-types" :key="key">
    <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-5">
      <div class="text-h6 font-weight-bold">Trip types</div>
      <div class="text--text">Here you can adjust available trip types</div>
    </div>
    <v-divider></v-divider>
    <v-card tile flat color="transparent" v-if="!data.length" class="d-flex justify-center align-center" height="300">
      <div>
        <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
        <div class="text-center text--text mt-4">Sorry, but nothing found</div>
      </div>
    </v-card>
    <v-data-table v-else :items-per-page="-1" hide-default-footer fixed-header :headers="headers" :items="data" item-key="id">
      <template v-slot:body="{ items }">
        <draggable :list="items" tag="tbody" @change="changeOrder($event)" handle=".handle">
          <tr v-for="item in items" :key="item.id" height="50" @click="openItem(item)">
            <td class="pr-0" style="width: 40px !important">
              <img class="move-icon pt-2 handle" src="@/assets/icon/move.svg" alt="icon" />
            </td>
            <td class="text-center pl-0" style="width: 30px !important">
              <v-checkbox
                @click.stop="checkbox(item)"
                class="mt-0 d-inline-block"
                v-model="item.active"
                color="success"
                hide-details
              ></v-checkbox>
            </td>
            <td class="py-2">
              <span class="text-body-1 black--text">{{ item.name_en }}</span
              ><br />{{ item.description_en }}
            </td>
            <td>{{ item.percent }}%</td>
            <td class="text-center">
              <v-icon color="primary">{{ item.discount_code_allowed ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </td>
            <td class="text-center">
              <v-icon color="primary">{{ item.mokafaa_allowed ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </td>
            <td class="text-center">
              <v-icon color="primary">{{ item.anb_allowed ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </td>
            <td class="text-center">
              <v-icon color="primary">{{ item.dw_allowed ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </td>
            <td class="text-center">
              <v-icon color="primary">{{ item.tabby_allowed ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div class="pl-lg-9 px-sm-7 py-3 px-3 mb-5">
      <v-btn @click="modal = true" depressed height="30" width="106" class="rounded primary">
        <span class="text-body-2">Add new</span>
      </v-btn>
    </div>
    <v-dialog v-model="modal" max-width="700">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">{{ modalData.id ? 'Edit Trip type' : 'Create Trip type' }}</div>
        <v-card flat outlined class="px-10 py-5">
          <div class="mb-3">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="6">
              <v-text-field
                class="field46"
                :error-messages="nameErrors"
                v-model="modalData.name"
                placeholder="Enter name"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col cols="6" class="pb-0">
              <v-text-field
                class="field46"
                v-model="modalData.name_en"
                :error-messages="nameEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                dir="rtl"
                class="field46"
                :error-messages="nameArErrors"
                v-model="modalData.name_ar"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                v-model="modalData.description_en"
                :error-messages="descEnErrors"
                placeholder="Enter description (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="30"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="descArErrors"
                v-model="modalData.description_ar"
                placeholder="Enter description (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="30"
              ></v-textarea>
            </v-col>
          </v-row>
          <div class="mb-2">Reward, %</div>
          <v-row>
            <v-col cols="6">
              <v-text-field
                class="field46"
                :error-messages="rewardErrors"
                v-model="modalData.percent"
                placeholder="Enter reward percen"
                type="number"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
          </v-row>
          <div>Loyalty programs</div>
          <div class="d-flex flex-wrap">
            <v-checkbox
              class="mr-15 d-inline-block black--text flex-grow-0 flex-shrink-0"
              v-model="modalData.discount_code_allowed"
              color="success"
              hide-details
              label="Discount Code"
            ></v-checkbox>
            <v-checkbox
              class="mr-15 d-inline-block black--text flex-grow-0 flex-shrink-0"
              v-model="modalData.mokafaa_allowed"
              color="success"
              hide-details
              label="Mokafaa"
            ></v-checkbox>
            <v-checkbox
              class="mr-15 d-inline-block black--text flex-grow-0 flex-shrink-0"
              v-model="modalData.anb_allowed"
              color="success"
              hide-details
              label="ANB"
            ></v-checkbox>
            <v-checkbox
              class="mr-15 d-inline-block black--text flex-grow-0 flex-shrink-0"
              v-model="modalData.dw_allowed"
              color="success"
              hide-details
              label="Digital wallet"
            ></v-checkbox>
            <v-checkbox
              class="mr-15 d-inline-block black--text flex-grow-0 flex-shrink-0"
              v-model="modalData.tabby_allowed"
              color="success"
              hide-details
              label="Tabby"
            ></v-checkbox>
          </div>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="modalData.id ? update() : save()" depressed class="primary rounded text-body-2" width="106" height="30">
            {{ modalData.id ? 'Save' : 'Create' }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      modal: false,
      modalData: {},
      key: 1,
      headers: [
        { text: '', value: 'icon', sortable: false, align: 'center' },
        { text: 'Enable', value: 'active', sortable: false, align: 'center', class: 'pl-0' },
        { text: 'Title', value: 'name_en', sortable: false },
        { text: 'Reward', value: 'dw_reward_percent', sortable: false },
        { text: 'Discount', value: 'discount_code_allowed', sortable: false, align: 'center' },
        { text: 'Mokafaa', value: 'mokafaa_allowed', sortable: false, align: 'center' },
        { text: 'ANB', value: 'anb_allowed', sortable: false, align: 'center' },
        { text: 'DW', value: 'dw_allowed', sortable: false, align: 'center' },
        { text: 'Tabby', value: 'tabby_allowed', sortable: false, align: 'center' },
      ],
      error: [],
    };
  },
  mounted() {
    this.data.length ? '' : this.$store.dispatch('getTripTypes');
  },
  methods: {
    notifi(btn) {
      if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    changeOrder(e) {
      this.updateNumber(e.moved.element.id, e.moved.newIndex);
    },
    openItem(i) {
      this.modal = true;
      this.$nextTick(() => {
        this.modalData = i;
      });
    },
    checkbox(i) {
      this.modalData = i;
      this.update();
    },
    async save() {
      this.error = [];
      const data = new Object();
      data.name = this.modalData.name || '';
      data.name_en = this.modalData.name_en || '';
      data.name_ar = this.modalData.name_ar || '';
      data.description_en = this.modalData.description_en || '';
      data.description_ar = this.modalData.description_ar || '';
      data.dw_reward_percent = +this.modalData.percent * 100 || 0;
      data.active = this.modalData.active || false;
      data.discount_code_allowed = this.modalData.discount_code_allowed || false;
      data.mokafaa_allowed = this.modalData.mokafaa_allowed || false;
      data.anb_allowed = this.modalData.anb_allowed || false;
      data.dw_allowed = this.modalData.dw_allowed || false;
      data.tabby_allowed = this.modalData.tabby_allowed || false;
      await this.$store
        .dispatch('createTripTypes', data)
        .then((res) => {
          res.data.percent = res.data.dw_reward_percent / 100 || 0;
          this.data.push(res.data);
          this.modal = false;
          ++this.key;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.name = this.modalData.name || '';
      data.name_en = this.modalData.name_en || '';
      data.name_ar = this.modalData.name_ar || '';
      data.description_en = this.modalData.description_en || '';
      data.description_ar = this.modalData.description_ar || '';
      data.dw_reward_percent = +this.modalData.percent * 100 || 0;
      data.active = this.modalData.active;
      data.discount_code_allowed = this.modalData.discount_code_allowed;
      data.mokafaa_allowed = this.modalData.mokafaa_allowed;
      data.anb_allowed = this.modalData.anb_allowed;
      data.dw_allowed = this.modalData.dw_allowed;
      data.tabby_allowed = this.modalData.tabby_allowed;
      await this.$store
        .dispatch('updateTripTypes', { id: this.modalData.id, data: data })
        .then(() => {
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async updateNumber(id, number) {
      this.error = [];
      const data = new Object();
      data.number = +number || '';
      await this.$store.dispatch('updateTripTypesNumber', { id: id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripTypes;
    },
    nameErrors() {
      const errors = [];
      this.error.find((item) => item == 'name__required') && errors.push('Please enter name');
      this.error.find((item) => item == 'name__invalid') && errors.push('Provided name is not valid');
      this.error.find((item) => item == 'name__exists') && errors.push('Sorry, but you can not add the same name');
      return errors;
    },
    nameEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'name_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'name_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    nameArErrors() {
      const errors = [];
      this.error.find((item) => item == 'name_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'name_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    descEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    descArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    rewardErrors() {
      const errors = [];
      this.error.find((item) => item == 'dw_reward_percent__required') && errors.push('Please enter reward percent');
      this.error.find((item) => item == 'dw_reward_percent__invalid') && errors.push('Provided reward percent is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.settings-types {
  table thead tr th {
    background: white !important;
  }
}
</style>
