<template>
  <div class="settings-support d-flex flex-column">
    <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-5">
      <div class="text-h6 font-weight-bold">Support service</div>
      <div class="text--text">Provide Whatsapp support phone number</div>
      <div class="mt-5 mb-2 font-weight-bold">Whatsapp phone</div>
      <v-text-field
        class="field46"
        style="max-width: 396px"
        v-model="data.whatsapp_phone"
        placeholder="Enter phone number"
        :error-messages="phoneErrors"
        outlined
        dense
        required
        color="primary"
        height="46"
      ></v-text-field>
    </div>
    <v-spacer></v-spacer>
    <div>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="save" depressed color="primary" class="rounded" width="106" height="30"><span class="text-body-2">Save</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getSettingsSupport');
  },
  methods: {
    async save() {
      this.error = [];
      const data = new Object();
      data.whatsapp_phone = this.data.whatsapp_phone || '';
      await this.$store.dispatch('updateSettingsSupport', data).catch((e) => {
        this.error = e.response.data.error;
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.settingsSupport;
    },
    phoneErrors() {
      const errors = [];
      this.error.find((item) => item == 'whatsapp_phone__required') && errors.push('Please enter phone number');
      this.error.find((item) => item == 'whatsapp_phone__invalid') && errors.push('Provided phone number is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.settings-support {
  height: 100%;
}
</style>
