<template>
  <div class="settings-web" :key="key">
    <!-- <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-4">
      <div class="text-h6 font-weight-bold">Web site</div>
      <div class="text--text">Web site settings</div>
    </div> -->
    <v-card tile flat min-height="52" class="d-flex flex-wrap align-center">
      <v-btn
        depressed
        :style="`box-shadow: ${web == j ? '4px -2px 4px rgba(0, 0, 0, 0.08)' : '1px -1px 4px rgba(0, 0, 0, 0.04)'}; z-index: ${
          webMenu.length - i
        }`"
        :color="web == j ? 'white' : 'gray lighten-3 text--text'"
        class="rounded-b-0 rounded-tl-0 px-7 me-n2"
        :height="52"
        @click="web = j"
        v-for="(j, i) in webMenu"
        :key="i"
      >
        {{ j }}
      </v-btn>
    </v-card>
    <v-divider style="position: relative; top: -1px"></v-divider>
    <div v-if="web == 'AppBar links'">
      <div class="pl-lg-9 px-sm-7 py-3 px-3">
        <div class="my-3 font-weight-bold">AppBar links</div>
        <draggable :list="data.home_appbar_links" @change="changeAppBarLinkOrder($event)" handle=".handle">
          <div v-for="item in data.home_appbar_links" :key="item.id" class="d-flex align-center">
            <img class="move-icon handle me-3 mt-n6" src="@/assets/icon/move.svg" alt="icon" />
            <v-card class="d-flex align-center rounded-lg mb-5 pa-4 pb-0 width100" outlined>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-textarea
                    class="field46"
                    v-model="item.title_en"
                    :error-messages="titleEnErrors"
                    placeholder="Enter title (eng)"
                    outlined
                    dense
                    required
                    color="primary"
                    rows="1"
                    auto-grow
                    row-height="38"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-textarea
                    dir="rtl"
                    class="field46"
                    :error-messages="titleArErrors"
                    v-model="item.title_ar"
                    placeholder="Enter title (ar)"
                    outlined
                    dense
                    required
                    color="primary"
                    rows="1"
                    auto-grow
                    row-height="38"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="pt-0 pb-2 mt-n2">
                  <v-text-field
                    class="field46"
                    :error-messages="targetErrors"
                    v-model="item.target"
                    placeholder="http/...."
                    outlined
                    dense
                    required
                    color="primary"
                    height="46"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="ml-md-13 ml-3 my-4" style="min-width: 160px">
                <v-btn @click="updateAppBarLink(item)" depressed outlined color="primary" class="rounded mr-2" width="100" height="38">
                  <v-icon color="primary">mdi-check</v-icon><span class="text-body-2">Save</span>
                </v-btn>
                <v-btn
                  @click="(modalAppBarLinkRemove = true), (modalData = item)"
                  depressed
                  outlined
                  color="error"
                  class="rounded"
                  width="48"
                  height="38"
                  icon
                >
                  <img class="error-filter" src="@/assets/icon/delete.svg" alt="icon" />
                </v-btn>
              </div>
            </v-card>
          </div>
        </draggable>
        <v-btn @click="modalAppBarLink = true" depressed height="30" width="106" class="rounded primary">
          <span class="text-body-2">Add new</span>
        </v-btn>
      </div>
    </div>
    <div v-else-if="web == 'Home sections'">
      <div class="pl-lg-9 px-sm-7 my-3 px-3 font-weight-bold">Home page sections</div>
      <v-divider></v-divider>
      <v-data-table :items-per-page="-1" hide-default-footer fixed-header :headers="headers" :items="data.home_sections" item-key="id">
        <template v-slot:body="{ items }">
          <draggable :list="items" tag="tbody" @change="changeOrder($event)" handle=".handle">
            <tr v-for="item in items" :key="item.id" height="50" @click="openItem(item)">
              <td class="pr-0" style="width: 40px !important">
                <img class="move-icon handle pt-2" src="@/assets/icon/move.svg" alt="icon" />
              </td>
              <td class="text-center pl-0" style="width: 30px !important">
                <v-checkbox
                  @click.stop="checkbox(item)"
                  class="mt-0 d-inline-block"
                  v-model="item.visible"
                  color="success"
                  hide-details
                ></v-checkbox>
              </td>
              <td>{{ item.type ? typeItems.find((i) => i.name == item.type).title : '' }}</td>
              <td>{{ item.title_en }}</td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
      <v-divider v-if="data.home_sections.length"></v-divider>
      <div class="pl-lg-9 px-sm-7 py-3 px-3">
        <v-btn @click="modal = true" depressed height="30" width="106" class="rounded primary">
          <span class="text-body-2">Add new</span>
        </v-btn>
      </div>
    </div>
    <div v-else-if="web == 'Banners config'">
      <div class="pl-lg-9 px-sm-7 my-3 px-3">
        <div class="font-weight-bold">Home banners config</div>
        <draggable :list="data.home_banners" @change="changeOrderBanner($event)" handle=".handle">
          <div v-for="item in data.home_banners" :key="item.id" class="d-flex align-center">
            <img class="move-icon handle me-3 mt-5" src="@/assets/icon/move.svg" alt="icon" />
            <v-card flat class="mt-5 white--text rounded-lg width100" height="265" :img="item.type != 'video' ? item.url : ''">
              <video v-if="item.type != 'picture'" controls height="265" width="100%" class="rounded-lg">
                <source controls :src="item.url" />
              </video>
              <div class="shadow">
                <div class="font-weight-bold text-h5 my-2">{{ item.title_en }}</div>
                <div style="max-width: 500px" class="font-weight-bold text-body-2">{{ item.description_en }}</div>
                <v-card outlined height="38" width="106" class="banner-btn d-flex align-center justify-space-around">
                  <v-btn icon @click="openItemBanner(item)"><img src="@/assets/icon/edit.svg" alt="pencil" /></v-btn>
                  <v-divider vertical></v-divider>
                  <v-btn icon @click="(modalRemove = true), (modalData = item)"><img src="@/assets/icon/delete.svg" alt="basket" /></v-btn>
                </v-card>
              </div>
            </v-card>
          </div>
        </draggable>
      </div>
      <v-divider class="mt-5"></v-divider>
      <div class="pl-lg-9 px-sm-7 py-3 px-3">
        <v-btn @click="modalBanner = true" depressed height="30" width="106" class="rounded primary">
          <span class="text-body-2">Add new</span>
        </v-btn>
      </div>
    </div>
    <div v-else-if="web == 'Advertisement'">
      <div class="pl-lg-9 px-sm-7 my-3 px-3">
        <div class="font-weight-bold">Advertisement</div>
        <v-card v-if="data.home_ad_banner" outlined class="width100 mt-2 pr-4 pl-3">
          <div class="d-flex align-center flex-md-nowrap flex-wrap">
            <v-text-field
              class="field46 mr-5 mt-6"
              v-model="data.home_ad_banner.target"
              placeholder="http/..."
              :error-messages="linkErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
            <div class="ml-5 my-4" style="min-width: 160px">
              <v-btn @click="updateNews" depressed outlined color="primary" class="rounded mr-2" width="100" height="38">
                <v-icon color="primary">mdi-check</v-icon><span class="text-body-2">Save</span>
              </v-btn>
              <v-btn @click="removeNews" depressed outlined color="error" class="rounded" width="48" height="38" icon>
                <img class="error-filter" src="@/assets/icon/delete.svg" alt="icon" />
              </v-btn>
            </div>
          </div>
          <v-card flat tile class="pb-2 width100" max-width="516">
            <div class="d-flex">
              <div class="me-3">
                <div class="font-weight-bold">Upload media in English</div>
                <div>Recommended size: 600x400px</div>
              </div>
              <v-card flat height="210" min-width="310" width="310" class="primary lighten-3 d-flex align-center justify-center rounded">
                <div
                  class="link width100 height100 d-flex align-center justify-center"
                  @click="changeImageNews('le')"
                  v-if="!data.home_ad_banner.url_large_en && !image1"
                >
                  <div class="primary--text text-center">
                    <img src="@/assets/icon/upload.svg" class="primary-filter" alt="icon" />
                    <div class="font-weight-bold">Upload media</div>
                    <div class="f13">600x400px</div>
                    <div class="f13">.jpeg </div>
                  </div>
                </div>
                <div v-else class="rounded width100 link" @click="changeImageNews('le')" style="height: 210px">
                  <img class="bg-picture width100 d-block rounded" height="210" :src="image1 || data.home_ad_banner.url_large_en" />
                  <div class="bg-picture-btn white--text text-center align-center justify-center flex-column">
                    <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                    <div class="font-weight-bold">Upload media</div>
                    <div class="f13">600x400px</div>
                    <div class="f13">.jpeg </div>
                  </div>
                </div>
              </v-card>
            </div>
            <v-card flat min-height="20" class="error--text text-caption">
              {{ picture1Errors.join('') }}
            </v-card>
            <div class="d-flex">
              <div class="me-3">
                <div class="font-weight-bold">Upload media in Arabic</div>
                <div>Recommended size: 600x400px</div>
              </div>
              <v-card flat height="210" min-width="310" width="310" class="primary lighten-3 d-flex align-center justify-center rounded">
                <div
                  class="link width100 height100 d-flex align-center justify-center"
                  @click="changeImageNews('la')"
                  v-if="!data.home_ad_banner.url_large_ar && !image2"
                >
                  <div class="primary--text text-center">
                    <img src="@/assets/icon/upload.svg" class="primary-filter" alt="icon" />
                    <div class="font-weight-bold">Upload media</div>
                    <div class="f13">600x400px</div>
                    <div class="f13">.jpeg </div>
                  </div>
                </div>
                <div v-else class="rounded width100 link" @click="changeImageNews('la')" style="height: 210px">
                  <img class="bg-picture width100 d-block rounded" height="210" :src="image2 || data.home_ad_banner.url_large_ar" />
                  <div class="bg-picture-btn white--text text-center align-center justify-center flex-column">
                    <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                    <div class="font-weight-bold">Upload media</div>
                    <div class="f13">600x400px</div>
                    <div class="f13">.jpeg </div>
                  </div>
                </div>
              </v-card>
            </div>
            <v-card flat min-height="20" class="error--text text-caption">
              {{ picture2Errors.join('') }}
            </v-card>
            <div class="d-flex">
              <div class="me-3">
                <div class="font-weight-bold">Upload media in English</div>
                <div>Recommended size: 300x400px</div>
              </div>
              <v-card flat tile min-width="310">
                <v-card flat height="260" min-width="196" width="196" class="primary lighten-3 d-flex align-center justify-center rounded">
                  <div
                    class="link width100 height100 d-flex align-center justify-center"
                    @click="changeImageNews('se')"
                    v-if="!data.home_ad_banner.url_small_en && !image3"
                  >
                    <div class="primary--text text-center">
                      <img src="@/assets/icon/upload.svg" class="primary-filter" alt="icon" />
                      <div class="font-weight-bold">Upload media</div>
                      <div class="f13">300x400px</div>
                      <div class="f13">.jpeg </div>
                    </div>
                  </div>
                  <div v-else class="rounded width100 link" @click="changeImageNews('se')" style="height: 260px">
                    <img class="bg-picture width100 d-block rounded" height="260" :src="image3 || data.home_ad_banner.url_small_en" />
                    <div class="bg-picture-btn white--text text-center align-center justify-center flex-column">
                      <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                      <div class="font-weight-bold">Upload media</div>
                      <div class="f13">300x400px</div>
                      <div class="f13">.jpeg </div>
                    </div>
                  </div>
                </v-card>
              </v-card>
            </div>
            <v-card flat min-height="20" class="error--text text-caption">
              {{ picture3Errors.join('') }}
            </v-card>
            <div class="d-flex">
              <div class="me-3">
                <div class="font-weight-bold">Upload media in Arabic</div>
                <div>Recommended size: 300x400px</div>
              </div>
              <v-card flat tile min-width="310">
                <v-card flat height="260" min-width="196" width="196" class="primary lighten-3 d-flex align-center justify-center rounded">
                  <div
                    class="link width100 height100 d-flex align-center justify-center"
                    @click="changeImageNews('sa')"
                    v-if="!data.home_ad_banner.url_small_ar && !image4"
                  >
                    <div class="primary--text text-center">
                      <img src="@/assets/icon/upload.svg" class="primary-filter" alt="icon" />
                      <div class="font-weight-bold">Upload media</div>
                      <div class="f13">300x400px</div>
                      <div class="f13">.jpeg </div>
                    </div>
                  </div>
                  <div v-else class="rounded width100 link" @click="changeImageNews('sa')" style="height: 260px">
                    <img class="bg-picture width100 d-block rounded" height="260" :src="image4 || data.home_ad_banner.url_small_ar" />
                    <div class="bg-picture-btn white--text text-center align-center justify-center flex-column">
                      <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                      <div class="font-weight-bold">Upload media</div>
                      <div class="f13">300x400px</div>
                      <div class="f13">.jpeg </div>
                    </div>
                  </div>
                </v-card>
              </v-card>
            </div>
            <v-card flat min-height="20" class="error--text text-caption">
              {{ picture4Errors.join('') }}
            </v-card>
          </v-card>
        </v-card>
      </div>
      <div class="pl-lg-9 px-sm-7 my-3 px-3" v-if="!data.home_ad_banner">
        <v-btn @click="modalNews = true" depressed height="30" width="106" class="rounded primary">
          <span class="text-body-2">Add new</span>
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="700">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">Home sections</div>
        <v-card flat outlined class="px-10 py-5">
          <div class="mb-3">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="12" :class="modalData.type == 'trips_group' ? 'pb-0' : ''">
              <v-select
                class="field46"
                :error-messages="typeErrors"
                v-model="modalData.type"
                :items="typeItems"
                placeholder="Enter type"
                item-text="title"
                item-value="name"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pt-0" v-if="modalData.type == 'trips_group'">
              <v-select
                class="field46"
                :error-messages="tripsGroupErrors"
                v-model="modalData.trips_group"
                :items="tripsGroups"
                placeholder="Enter trips group"
                item-text="title"
                item-value="id"
                return-object
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col cols="6">
              <v-text-field
                class="field46"
                v-model="modalData.title_en"
                :error-messages="titleEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dir="rtl"
                class="field46"
                :error-messages="titleArErrors"
                v-model="modalData.title_ar"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="modalData.id ? update() : save()" depressed class="primary rounded text-body-2" width="106" height="30">
            Done
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalRemove" max-width="480">
      <v-card flat color="pa-4">
        <div class="text-h6 font-weight-bold">Do you really want to remove home banner?</div>
        <div class="text-end mb-1 mt-5">
          <v-btn @click="modalRemove = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="removeBanner" depressed class="error rounded text-body-2" width="106" height="30">Remove</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalBanner" max-width="700">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">{{ modalData.id ? 'Home banner desctiption' : 'New home banner' }}</div>
        <v-card flat outlined class="px-10 py-5">
          <v-card flat height="220" class="gray darken-5 d-flex align-center justify-center rounded" v-if="!modalData.id">
            <div class="white--text text-center link width100 height100" @click="changeImage" v-if="!modalData.picture && !image">
              <img class="mt-12" src="@/assets/icon/upload.svg" alt="icon" />
              <div class="font-weight-bold">Select picture or video for banner</div>
              <div class="text-body-2">Optional dimensions 1800x600 px</div>
            </div>
            <div v-else class="rounded width100 link" @click="changeImage" style="height: 220px">
              <img
                class="bg-picture width100 d-block rounded"
                height="220"
                :src="image || modalData.picture"
                v-if="file.type.substr(0, 5) != 'video'"
              />
              <video v-else height="220" width="100%" class="rounded">
                <source controls :src="image" />
              </video>
              <div class="bg-picture-btn white--text text-center align-cente justify-center flex-column">
                <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                <div class="font-weight-bold">Select picture or video for banner</div>
                <div class="text-body-2 mb-3">Optional dimensions 1800x600 px</div>
              </div>
            </div>
          </v-card>
          <v-card flat min-height="20" v-if="!modalData.id" class="error--text text-caption">
            {{ pictureErrors.join('') }}
          </v-card>
          <div class="mb-5">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                class="field46"
                v-model="modalData.title_en"
                :error-messages="titleEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                dir="rtl"
                class="field46"
                :error-messages="titleArErrors"
                v-model="modalData.title_ar"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                v-model="modalData.description_en"
                :error-messages="descEnErrors"
                placeholder="Enter description (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="30"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="descArErrors"
                v-model="modalData.description_ar"
                placeholder="Enter description (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="30"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modalBanner = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn
            @click="modalData.id ? updateBanner() : saveBanner()"
            depressed
            class="primary rounded text-body-2"
            width="106"
            height="30"
          >
            Done
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalNews" max-width="600">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">Advertisement</div>
        <v-card flat outlined class="px-10 py-3 view-without-scroll" max-height="510">
          <div>Link</div>
          <v-text-field
            class="field46"
            v-model="modalData.target"
            :error-messages="linkErrors"
            placeholder="http/..."
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <div class="d-flex">
            <div class="me-3">
              <div class="font-weight-bold">Upload media in English</div>
              <div>Recommended size: 600x400px</div>
            </div>
            <v-card
              flat
              height="210"
              min-width="310"
              width="310"
              class="gray darken-5 d-flex align-center justify-center rounded"
              v-if="!modalData.id"
            >
              <div
                class="link width100 height100 d-flex align-center justify-center"
                @click="changeImageNews('le')"
                v-if="!modalData.picture && !image1"
              >
                <div class="white--text text-center">
                  <img src="@/assets/icon/upload.svg" alt="icon" />
                  <div>600x400px</div>
                </div>
              </div>
              <div v-else class="rounded width100 link" @click="changeImageNews('le')" style="height: 210px">
                <img class="bg-picture width100 d-block rounded" height="210" :src="image1" v-if="file1.type.substr(0, 5) != 'video'" />
                <video v-else height="210" width="100%" class="rounded">
                  <source controls :src="image1" />
                </video>
                <div class="bg-picture-btn white--text text-center align-center justify-center flex-column">
                  <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                  <div>600x400px</div>
                </div>
              </div>
            </v-card>
          </div>
          <v-card flat min-height="20" class="error--text text-caption">
            {{ picture1Errors.join('') }}
          </v-card>
          <div class="d-flex">
            <div class="me-3">
              <div class="font-weight-bold">Upload media in Arabic</div>
              <div>Recommended size: 600x400px</div>
            </div>
            <v-card
              flat
              height="210"
              min-width="310"
              width="310"
              class="gray darken-5 d-flex align-center justify-center rounded"
              v-if="!modalData.id"
            >
              <div
                class="link width100 height100 d-flex align-center justify-center"
                @click="changeImageNews('la')"
                v-if="!modalData.picture && !image2"
              >
                <div class="white--text text-center">
                  <img src="@/assets/icon/upload.svg" alt="icon" />
                  <div>600x400px</div>
                </div>
              </div>
              <div v-else class="rounded width100 link" @click="changeImageNews('la')" style="height: 210px">
                <img class="bg-picture width100 d-block rounded" height="210" :src="image2" v-if="file2.type.substr(0, 5) != 'video'" />
                <video v-else height="210" width="100%" class="rounded">
                  <source controls :src="image2" />
                </video>
                <div class="bg-picture-btn white--text text-center align-center justify-center flex-column">
                  <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                  <div>600x400px</div>
                </div>
              </div>
            </v-card>
          </div>
          <v-card flat min-height="20" class="error--text text-caption">
            {{ picture2Errors.join('') }}
          </v-card>
          <div class="d-flex">
            <div class="me-3">
              <div class="font-weight-bold">Upload media in English</div>
              <div>Recommended size: 300x400px</div>
            </div>
            <v-card flat tile min-width="310">
              <v-card
                flat
                height="260"
                min-width="196"
                width="196"
                class="gray darken-5 d-flex align-center justify-center rounded"
                v-if="!modalData.id"
              >
                <div
                  class="link width100 height100 d-flex align-center justify-center"
                  @click="changeImageNews('se')"
                  v-if="!modalData.picture && !image3"
                >
                  <div class="white--text text-center">
                    <img src="@/assets/icon/upload.svg" alt="icon" />
                    <div>300x400px</div>
                  </div>
                </div>
                <div v-else class="rounded width100 link" @click="changeImageNews('se')" style="height: 260px">
                  <img class="bg-picture width100 d-block rounded" height="260" :src="image3" v-if="file3.type.substr(0, 5) != 'video'" />
                  <video v-else height="260" width="100%" class="rounded">
                    <source controls :src="image3" />
                  </video>
                  <div class="bg-picture-btn white--text text-center align-center justify-center flex-column">
                    <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                    <div>300x400px</div>
                  </div>
                </div>
              </v-card>
            </v-card>
          </div>
          <v-card flat min-height="20" class="error--text text-caption">
            {{ picture3Errors.join('') }}
          </v-card>
          <div class="d-flex">
            <div class="me-3">
              <div class="font-weight-bold">Upload media in Arabic</div>
              <div>Recommended size: 300x400px</div>
            </div>
            <v-card flat tile min-width="310">
              <v-card
                flat
                height="260"
                min-width="196"
                width="196"
                class="gray darken-5 d-flex align-center justify-center rounded"
                v-if="!modalData.id"
              >
                <div
                  class="link width100 height100 d-flex align-center justify-center"
                  @click="changeImageNews('sa')"
                  v-if="!modalData.picture && !image4"
                >
                  <div class="white--text text-center">
                    <img src="@/assets/icon/upload.svg" alt="icon" />
                    <div>300x400px</div>
                  </div>
                </div>
                <div v-else class="rounded width100 link" @click="changeImageNews('sa')" style="height: 260px">
                  <img class="bg-picture width100 d-block rounded" height="260" :src="image4" v-if="file4.type.substr(0, 5) != 'video'" />
                  <video v-else height="260" width="100%" class="rounded">
                    <source controls :src="image4" />
                  </video>
                  <div class="bg-picture-btn white--text text-center align-center justify-center flex-column">
                    <img height="44" src="@/assets/icon/upload.svg" alt="icon" />
                    <div>300x400px</div>
                  </div>
                </div>
              </v-card>
            </v-card>
          </div>
          <v-card flat min-height="20" class="error--text text-caption">
            {{ picture4Errors.join('') }}
          </v-card>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modalNews = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="saveNews" depressed class="primary rounded text-body-2" width="106" height="30">Done</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalAppBarLink" max-width="700">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">AppBar Link</div>
        <v-card flat outlined class="px-10 py-5">
          <div class="mb-3">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-textarea
                class="field46"
                v-model="modalData.title_en"
                :error-messages="titleEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                rows="1"
                auto-grow
                row-height="38"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="titleArErrors"
                v-model="modalData.title_ar"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                rows="1"
                auto-grow
                row-height="38"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="py-0 mt-n2">
              <div class="mb-1">Link</div>
              <v-text-field
                class="field46"
                :error-messages="targetErrors"
                v-model="modalData.target"
                placeholder="http/...."
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn
            @click="modalAppBarLink = false"
            depressed
            class="me-3 rounded"
            outlined
            width="106"
            height="30"
            text
            color="gray lighten-1"
          >
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="saveAppBarLink" depressed class="primary rounded text-body-2" width="106" height="30">Done</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalAppBarLinkRemove" max-width="480">
      <v-card flat color="pa-4">
        <div class="text-h6 font-weight-bold">Do you really want to remove {{ modalData.title_en }} AppBar link ?</div>
        <div class="text-end mb-1 mt-5">
          <v-btn
            @click="modalAppBarLinkRemove = false"
            depressed
            class="me-3 rounded"
            outlined
            width="106"
            height="30"
            text
            color="gray lighten-1"
          >
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="removeAppBarLink" depressed class="error rounded text-body-2" width="106" height="30">Remove</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      modal: false,
      modalBanner: false,
      modalRemove: false,
      modalNews: false,
      modalAppBarLink: false,
      modalAppBarLinkRemove: false,
      modalData: {},
      image: '',
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      file: '',
      file1: '',
      file2: '',
      file3: '',
      file4: '',
      key: 1,
      web: 'AppBar links',
      webMenu: ['AppBar links', 'Home sections', 'Banners config', 'Advertisement'],
      headers: [
        { text: '', value: 'icon', sortable: false, align: 'center' },
        { text: 'Visible', value: 'visible', sortable: false, align: 'center', class: 'pl-0' },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Title', value: 'title_en', sortable: false },
      ],
      typeItems: [
        { name: 'trips', title: 'Trips' },
        { name: 'trip_request', title: 'Trip request' },
        { name: 'experts', title: 'Experts' },
        { name: 'travel_experiences', title: 'Travel experiences' },
        { name: 'surprise_trips', title: 'Surprise trips' },
        { name: 'trip_filters', title: 'Trip filters' },
        { name: 'featured_trips', title: 'Featured trips' },
        { name: 'inspiration', title: 'Inspiration' },
        { name: 'reviews', title: 'Reviews' },
        { name: 'trips_group', title: 'Trips group' },
        { name: 'partners', title: 'Partners' },
      ],
      error: [],
    };
  },
  mounted() {
    this.data.length ? '' : this.$store.dispatch('getSettingsWeb');
    this.tripsGroups.length ? '' : this.$store.dispatch('getTripsGroupsShort');
  },
  methods: {
    notifi(btn) {
      if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    changeOrder(e) {
      this.updateOrder(e.moved.element.id, e.moved.newIndex);
    },
    openItem(i) {
      this.modal = true;
      this.$nextTick(() => {
        this.modalData = Object.assign({}, i);
      });
    },
    checkbox(i) {
      this.modalData = i;
      this.update();
    },
    async save() {
      this.error = [];
      const data = new Object();
      data.type = this.modalData.type || '';
      data.title_en = this.modalData.title_en || '';
      data.title_ar = this.modalData.title_ar || '';
      data.visible = this.modalData.visible || false;
      this.modalData.type == 'trips_group' ? (data.trips_group = this.modalData.trips_group.id) : '';
      await this.$store
        .dispatch('addSettingsWebHome', data)
        .then(() => {
          this.modal = false;
          ++this.key;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.type = this.modalData.type || '';
      data.title_en = this.modalData.title_en || '';
      data.title_ar = this.modalData.title_ar || '';
      data.visible = this.modalData.visible || false;
      this.modalData.type == 'trips_group' ? (data.trips_group = this.modalData.trips_group.id) : '';
      await this.$store
        .dispatch('updateSettingsWebHome', { id: this.modalData.id, data: data })
        .then(() => {
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async updateOrder(id, order) {
      this.error = [];
      const data = new Object();
      data.order = order;
      await this.$store.dispatch('updateSettingsWebHomeOrder', { id: id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    openItemBanner(i) {
      this.modalBanner = true;
      this.$nextTick(() => {
        this.modalData = Object.assign({}, i);
      });
    },
    changeOrderBanner(e) {
      this.updateBannerOrder(e.moved.element.id, e.moved.newIndex);
    },
    changeImage() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', '.mp4,.mov,image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    changeImageNews(i) {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          if (i == 'le') {
            self.file1 = this.files[0];
          } else if (i == 'la') {
            self.file2 = this.files[0];
          } else if (i == 'se') {
            self.file3 = this.files[0];
          } else if (i == 'sa') {
            self.file4 = this.files[0];
          }
          var reader = new FileReader();
          reader.onload = (e) => {
            if (i == 'le') {
              self.image1 = e.target.result;
            } else if (i == 'la') {
              self.image2 = e.target.result;
            } else if (i == 'se') {
              self.image3 = e.target.result;
            } else if (i == 'sa') {
              self.image4 = e.target.result;
            }
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async saveBanner() {
      this.error = [];
      const formData = new FormData();
      if (this.file.type.substr(0, 5) != 'video') {
        formData.append('picture', this.file);
        formData.append('type', 'picture');
      } else {
        formData.append('video', this.file);
        formData.append('type', 'video');
      }
      this.modalData.title_en ? formData.append('title_en', this.modalData.title_en) : '';
      this.modalData.title_ar ? formData.append('title_ar', this.modalData.title_ar) : '';
      this.modalData.description_en ? formData.append('description_en', this.modalData.description_en) : '';
      this.modalData.description_ar ? formData.append('description_ar', this.modalData.description_ar) : '';
      await this.$store
        .dispatch('addSettingsWebBanner', formData)
        .then(() => {
          this.modalBanner = false;
          this.modalData = {};
          this.image = '';
          this.file = '';
          ++this.key;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateBanner() {
      this.error = [];
      const data = new Object();
      data.title_en = this.modalData.title_en || '';
      data.title_ar = this.modalData.title_ar || '';
      data.description_en = this.modalData.description_en || '';
      data.description_ar = this.modalData.description_ar || '';
      await this.$store
        .dispatch('updateSettingsWebBanner', { id: this.modalData.id, data: data })
        .then(() => {
          this.modalBanner = false;
          this.modalData = {};
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async removeBanner() {
      this.error = [];
      await this.$store
        .dispatch('removeSettingsWebBanner', this.modalData.id)
        .then(() => {
          this.$store.dispatch('getSettingsWeb');
          this.modalRemove = false;
          this.modalData = {};
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async updateBannerOrder(id, order) {
      this.error = [];
      const data = new Object();
      data.order = order;
      await this.$store.dispatch('updateSettingsWebBannerOrder', { id: id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    async saveNews() {
      this.error = [];
      const formData = new FormData();
      this.file1 ? formData.append('object_large_en', this.file1) : '';
      this.file2 ? formData.append('object_large_ar', this.file2) : '';
      this.file3 ? formData.append('object_small_en', this.file3) : '';
      this.file4 ? formData.append('object_small_ar', this.file4) : '';
      this.modalData.target ? formData.append('target', this.modalData.target) : '';
      await this.$store
        .dispatch('addSettingsWebAdBanner', formData)
        .then(() => {
          this.modalNews = false;
          ++this.key;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateNews() {
      this.error = [];
      const formData = new FormData();
      this.file1 ? formData.append('object_large_en', this.file1) : '';
      this.file2 ? formData.append('object_large_ar', this.file2) : '';
      this.file3 ? formData.append('object_small_en', this.file3) : '';
      this.file4 ? formData.append('object_small_ar', this.file4) : '';
      formData.append('target', this.data.home_ad_banner.target);
      await this.$store.dispatch('updateSettingsWebAdBanner', formData).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    async removeNews() {
      this.error = [];
      await this.$store
        .dispatch('removeSettingsWebAdBanner', this.data.home_ad_banner.id)
        .then(() => {
          this.$store.dispatch('getSettingsWeb');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    changeAppBarLinkOrder(e) {
      this.updateAppBarLinkOrder(e.moved.element.id, e.moved.newIndex);
    },
    async saveAppBarLink() {
      this.error = [];
      const data = new Object();
      data.title_en = this.modalData.title_en || '';
      data.title_ar = this.modalData.title_ar || '';
      data.target = this.modalData.target || '';
      await this.$store
        .dispatch('addSettingsWebAppBarLink', data)
        .then(() => {
          this.modalAppBarLink = false;
          this.modalData = {};
          ++this.key;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateAppBarLink(i) {
      this.error = [];
      const data = new Object();
      data.title_en = i.title_en || '';
      data.title_ar = i.title_ar || '';
      data.target = i.target || '';
      await this.$store.dispatch('updateSettingsWebAppBarLink', { id: i.id, data: data }).catch((e) => {
        this.error = e.response.data.error;
        this.modal ? '' : this.notifi('500');
      });
    },
    async removeAppBarLink() {
      this.error = [];
      await this.$store
        .dispatch('removeSettingsWebAppBarLink', this.modalData.id)
        .then(() => {
          this.$store.dispatch('getSettingsWeb');
          this.modalAppBarLinkRemove = false;
          this.modalData = {};
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async updateAppBarLinkOrder(id, order) {
      this.error = [];
      const data = new Object();
      data.order = order;
      await this.$store.dispatch('updateSettingsWebAppBarLinkOrder', { id: id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = {};
    },
    modalBanner() {
      this.error = [];
      this.modalData = {};
      this.image = '';
      this.file = '';
    },
    modalNews() {
      this.error = [];
      this.modalData = {};
      this.image1 = '';
      this.image2 = '';
      this.image3 = '';
      this.image4 = '';
      this.file1 = '';
      this.file2 = '';
      this.file3 = '';
      this.file4 = '';
    },
    modalAppBarLink() {
      this.error = [];
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.settingsWeb;
    },
    tripsGroups() {
      return this.$store.getters.tripsGroupsShort;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Selected type is not valid');
      this.error.find((item) => item == 'type__exists') &&
        errors.push('Sorry, but you can not add more than one section with the same type');
      return errors;
    },
    tripsGroupErrors() {
      const errors = [];
      this.error.find((item) => item == 'trips_group__required') && errors.push('Please select trips group');
      this.error.find((item) => item == 'trips_group__invalid') && errors.push('Selected trips group is not valid');
      return errors;
    },
    pictureErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select media');
      this.error.find((item) => item == 'picture__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'picture__invalid') && errors.push('Selected picture is not valid');
      this.error.find((item) => item == 'video__required') && errors.push('Please select video');
      this.error.find((item) => item == 'video__invalid') && errors.push('Selected video is not valid');
      return errors;
    },
    picture1Errors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select media');
      this.error.find((item) => item == 'object_large_en__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'object_large_en__invalid') && errors.push('Selected picture is not valid');
      this.error.find((item) => item == 'video__required') && errors.push('Please select video');
      this.error.find((item) => item == 'video__invalid') && errors.push('Selected video is not valid');
      return errors;
    },
    picture2Errors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select media');
      this.error.find((item) => item == 'object_large_ar__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'object_large_ar__invalid') && errors.push('Selected picture is not valid');
      return errors;
    },
    picture3Errors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select media');
      this.error.find((item) => item == 'object_small_en__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'object_small_en__invalid') && errors.push('Selected picture is not valid');
      return errors;
    },
    picture4Errors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select media');
      this.error.find((item) => item == 'object_small_ar__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'object_small_ar__invalid') && errors.push('Selected picture is not valid');
      return errors;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    targetErrors() {
      const errors = [];
      this.error.find((item) => item == 'target__required') && errors.push('Please enter link');
      this.error.find((item) => item == 'target__invalid') && errors.push('Provided link is not valid');
      return errors;
    },
    descEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    descArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    linkErrors() {
      const errors = [];
      this.error.find((item) => item == 'target__required') && errors.push('Please enter url');
      this.error.find((item) => item == 'target__invalid') && errors.push('Provided url is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.bg-picture {
  object-fit: cover;
}
.bg-picture-btn {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba($color: #3d3d3d, $alpha: 0.6);
  z-index: 9;
}
.rounded:hover {
  .bg-picture-btn {
    display: flex;
  }
}
.settings-web {
  table thead tr th {
    background: white !important;
  }
  .banner-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    padding: 30px;
    background: linear-gradient(
      180.18deg,
      rgba(32, 102, 165, 0.6) 0.16%,
      rgba(32, 102, 165, 0.409375) 42.21%,
      rgba(32, 102, 165, 0) 99.84%
    );
  }
  .v-btn.primary--text {
    background: var(--v-primary-lighten3);
  }
  .v-btn.error--text {
    background: var(--v-error-lighten5);
  }
}
</style>
