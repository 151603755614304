<template>
  <div class="settings-request d-flex flex-column">
    <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-5">
      <div class="text-h6 font-weight-bold">Trip request</div>
      <div class="text--text">Select mininium days before the Trip starts</div>
      <div class="mt-5 mb-2 font-weight-bold">Days, min</div>
      <v-text-field
        class="field46"
        style="max-width: 186px"
        v-model="data.min_days_before_start"
        placeholder="Enter number"
        :error-messages="dayErrors"
        outlined
        dense
        required
        type="number"
        color="primary"
        height="46"
      ></v-text-field>
    </div>
    <v-spacer></v-spacer>
    <div>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="save" depressed color="primary" class="rounded" width="106" height="30"><span class="text-body-2">Save</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getSettingsTripRequest');
  },
  methods: {
    async save() {
      this.error = [];
      const data = new Object();
      data.min_days_before_start = +this.data.min_days_before_start || '';
      await this.$store.dispatch('updateSettingsTripRequest', data).catch((e) => {
        this.error = e.response.data.error;
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.settingsTripRequest;
    },
    dayErrors() {
      const errors = [];
      this.error.find((item) => item == 'min_days_before_start__required') && errors.push('Please enter min days');
      this.error.find((item) => item == 'min_days_before_start__invalid') && errors.push('Provided min days is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.settings-request {
  height: 100%;
}
</style>
